<template>
<div v-if="pack && pack.slug !== 'ydkj-classic'" class="plat-icons-container">
    <div v-for="(platform, idx) in platformOptions" :key="idx">
        <NuxtLink
            :href="storefrontLink(pack.slug, platform.vendor)"
            target="_blank"
            :aria-label="`link to ${platform.vendor} storefront`"
            tabindex="0"
            class="external-storefront"
            :class="[platform.vendor, getProductClass(pack.slug)]"
            external
        >
            <img
                :src="`/images/icons/platforms/${platform.icon}.svg`"
                :alt="platform.text"
                class="storefront-icon"
            >
        </NuxtLink>
    </div>
</div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { getStorefrontLink, getProductGTMClass } from '$services/storefronts/storefronts'
import type { JBGWeb } from '$types/JBGWeb'
import { Geolocate } from '$services/geolocate'

export default defineNuxtComponent({
    props: {
        pack: Object as PropType<JBGWeb.PackDetail>,
        platformOptions: Object,
        fellBackToEn: {
            type: Boolean,
            required: false,
            default: false
        },
        isBundle: {
            type: Boolean,
            required: false,
            default: false
        },
        isLocalized: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        cvc() {
            return Geolocate.getCountryCode()
        }
    },

    watch: {
        // if the cloudfront-viewer-country cookie changes, reload the app.
        cvc() {
            reloadNuxtApp()
        }
    },

    methods: {
        imageSrc(platform: JBGWeb.DropdownOption) {
            if (!platform) return null
            return new URL(`../../assets/icons/${platform.icon}.svg`, import.meta.url).href
        },

        getProductClass(packSlug: string) {
            return getProductGTMClass(packSlug)
        },

        storefrontLink(slug: string, platform: string): URL | string | undefined {
            if (!platform || !slug) return
            if (!this.pack) return
            const { locale } = useI18n()
            return getStorefrontLink(locale.value, slug, platform, this.fellBackToEn, this.isBundle, this.isLocalized)
        }
    }
})
</script>

<style scoped lang="scss">
@use "$styles/kit.scss" as *;

.plat-icons-container {
    display: flex;
    justify-content: space-around;
    padding: 12px 0;

    .storefront-icon {
        margin: 0 6px;
        height: 28px;
        width: 28px;

        @include mq-small {
            margin: 0px 2px;
            height: 20px;
            width: 20px;
        }

        @include mq-xsmall {
            height: 20px;
            width: 20px;
        }
    }
}
</style>
